import React from "react";
import { Col, Container, Jumbotron, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "../../public/style.scss";
import ContactUs from "../components/ContactUs";
import Navbar from "../components/NavBar";
import imageRakkennus from "../images/building-1080602_1920.jpg";
import tickImage from "../images/path9021.png";

const IndexPage = () => {
  return (
    <>
      <Jumbotron
        className="p-0 mb-0 JumbotronContainer"
        style={{ zIndex: "10" }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>TxT Rakennus</title>
          <link rel="canonical" href="https://txtrakennus.fi" />
          <meta
            name="description"
            content="Suomessa toimiva rakennusyritys, jolla on vuosien kokemus mustalevyistä ja erilaisista rakennustöistä."
          />
        </Helmet>
        <Navbar style={{ zIndex: "1" }} />
        <div style={{ zIndex: "3" }}>
          <div className="fillContainer">
            <img
              src={imageRakkennus}
              className="img-fluid d-block fillImage imageFitObject"
              alt="man checing the tiles"
            />
          </div>
          <div className="blackOverlay" />
          <div className="centeredTextOnImage text-center">
            <h1 className="font-wight-bold display-3 text-primary logoFont">
              TxT Rakennus
            </h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
              sequi quam aperiam earum, ipsum neque debitis in odio, fuga iusto
              officiis fugit voluptas cumque sunt omnis nihil temporibus error
              recusandae?
            </p>
          </div>
        </div>
        <div
          className="custom-shape-divider-bottom-1614604193"
          style={{ zIndex: "2" }}
        >
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </Jumbotron>
      <main>
        <Jumbotron
          className="mb-0 rounded-0"
          style={{ background: "var(--white)" }}
        >
          <Row className="d-flex align-items-center">
            <Col xs={12} sm={6} className="DifferentFont-Family pl-sm-5">
              <h2 className="font-weight-bold">
                We are a company that can do:
              </h2>
              <p className="text-muted">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo
                ab officia sed vel ratione reiciendis tempora eligendi aliquid
              </p>
              <ul className="list-unstyled services mt-5 ml-5">
                <li>
                  <img src={tickImage} alt="tick" />
                  Lorem ipsum dolor sit amet, consectetur
                </li>
                <li>
                  <img src={tickImage} alt="tick" /> Lorem ipsum dolor sit amet,
                  consectetur
                </li>
                <li>
                  <img src={tickImage} alt="tick" /> Lorem ipsum dolor sit amet,
                  consectetur
                </li>
                <li>
                  <img src={tickImage} alt="tick" /> Lorem ipsum dolor sit amet,
                  consectetur
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={6} className="d-flex justify-content-center mb-5">
              <ContactUs className="contactUs text-darkprimary" />
            </Col>
          </Row>
        </Jumbotron>
      </main>
      <footer>
        <Jumbotron className="mb-0 bg-secondary text-darkprimary rounded-0">
          <Container>
            <Row>
              <Col xs sm={6} className="text-center">
                <h3 className="logoFont">TxT Rakennus</h3>
                <p className="lead">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Dolorem error animi aspernatur ut fugiat eos repellat labore
                  quasi sed perferendis! Ipsum harum ipsa perspiciatis corporis
                  nam quaerat modi possimus eos!
                </p>
              </Col>
              <Col xs sm={6}>
                <div className="d-flex justify-content-center">
                  <ul className="list-unstyled list-contactUs">
                    <li>Phone: 056343</li>
                    <li className="d-flex justify-content-center align-items-center">
                      <p className="mt-2 mr-2">Email:</p>
                      <div className="d-flex justify-content-center flex-column">
                        <a href="mailto:tonis@txtrakennus.fi">
                          tonis@txtrakennus.fi
                        </a>
                        <a href="mailto:tiit@txtrakennus.fi">tiit@txtrakennus.fi</a>
                      </div>
                    </li>
                    <li>Location: aadsasa</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
        <Jumbotron className="mb-0 p-3 rounded-0 text-center bg-black w-100">
          <a href="https://innopages.fi/">Powerd By Innopages ©</a>
        </Jumbotron>
      </footer>
    </>
  );
};

export default IndexPage;
