import React, { useState } from "react";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { Alert } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    msg: "",
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    msg: "",
    variant: "",
  });
  const [reCaptchValue, setRecapatchValue] = useState("");
  function onChange(value) {
    setRecapatchValue(value);
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setAlert({ open: false });
    setLoading(true);
    if (
      reCaptchValue !== "" &&
      validateEmail(formData.email) &&
      formData.name !== "" &&
      formData.phoneNumber !== ""
    ) {
      axios({
        method: "post",
        url: "https://txtrakennus.fi/static/api/contact/index.php", //change this to correct endpoint
        headers: { "content-type": "application/json" },
        data: {
          firstName: formData.name,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          msg: formData.msg,
        },
      })
        .then((result) => {
          setLoading(false);
          if (result.data.sent) {
            setAlert({
              variant: "success",
              msg: "Sähköposti lähetetty",
              open: true,
            });
          } else {
            setAlert({
              open: true,
              msg: "Täytä lomake",
              variant: "danger",
            });
          }
        })
        .catch((error) => error);
    } else {
      setLoading(false);

      setAlert({
        open: true,
        msg: "Täytä lomake",
        variant: "danger",
      });
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  return (
    <Form className={props.className}>
      <h2 className="font-weight-bold">Ota meihin yhteyttä</h2>
      <Alert
        variant={alert.variant}
        hidden={!alert.open}
        onClose={() => setAlert({ open: false })}
        dismissible
      >
        {alert.msg}
      </Alert>
      <Form.Group as={Col} controlId="formGridName">
        <Form.Label>Nimi *</Form.Label>
        <Form.Control
          value={formData.name}
          onChange={(e) => {
            setFormData({ ...formData, name: e.target.value });
          }}
          type="text"
          placeholder="Kirjoita nimi"
        />
      </Form.Group>
      <Form.Group as={Col} controlId="formGridNumber">
        <Form.Label>Puhelinnumero *</Form.Label>
        <Form.Control
          value={formData.phoneNumber}
          onChange={(e) => {
            setFormData({ ...formData, phoneNumber: e.target.value });
          }}
          type="number"
          placeholder="Kirjoita puhelinnumero"
        />
      </Form.Group>
      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label>Sähköposti *</Form.Label>
        <Form.Control
          value={formData.email}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value });
          }}
          type="email"
          placeholder="Kirjoita sähköposti"
        />
      </Form.Group>
      <Form.Group as={Col} controlId="exampleForm.ControlTextarea1">
        <Form.Label>Viesti</Form.Label>
        <Form.Control
          value={formData.msg}
          onChange={(e) => {
            setFormData({ ...formData, msg: e.target.value });
          }}
          as="textarea"
          rows={3}
          className="w-100"
        />
      </Form.Group>

      <Form.Group as={Col} controlId="recaptchaId">
        <ReCAPTCHA
          sitekey="6Ld2B3gaAAAAAOGQas4cF-Rqf4lU5uQ3UyVHfWwu"
          onChange={onChange}
        />
      </Form.Group>
      <div className="d-flex justify-content-center">
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <Button
            variant="darkprimary"
            className="w-50 rounded "
            onClick={handleFormSubmit}
          >
            Lähettää
          </Button>
        )}
      </div>
    </Form>
  );
};

export default ContactUs;
