import React from "react";
import { Navbar, Nav } from "react-bootstrap";

const NavBar = (props) => {
  return (
    <Navbar bg="light" className="text-white" expand="sm" style={{ ...props.style }}>
      <Navbar.Brand href="#home" className="font-weight-bold lead logoFont" style={{fontSize:'1.5rem'}}>
        TxT Rakennus
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="#home">ETUSIVU</Nav.Link>
          <Nav.Link href="#contactUs">YHTEYDENOTTO</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
